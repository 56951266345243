<template>
    <div class="main_bd">
        <div class="head_title">Merchants List</div>
        <div class="mod_default_box permission">
            <div style="margin-bottom: 10px;">
                <a-input v-model="search" @keyup="doKeyUp" placeholder="Search" style="width: 200px; margin-right: 10px;"></a-input>
                <a-button @click="get_minipass_merchant_list">Search</a-button>
                <div style="display: inline-block; float: right;">
                    <span style="padding-right: 10px;">Sort By</span>
                    <a-select @change="get_minipass_merchant_list" v-model="sort_by" style="width: 230px; margin-right: 10px;">
                        <a-select-option value="id">Default</a-select-option>
                        <a-select-option value="waitlist_count_month">Waitlist count (By month)</a-select-option>
                        <a-select-option value="reserve_count_month">Reserve count (By month)</a-select-option>
                        <a-select-option value="popular_count_total">Popular count (By month)</a-select-option>
                    </a-select>
                </div>
            </div>
            <a-table :pagination="false" :rowKey="data => data.sid" :columns="columns" :data-source="merchant_list" >
                <template slot="ai_status" slot-scope="text, data">
                    <a-switch v-model="data.ai_enable" @change="update_status(data, 'ai', $event)" />
                </template>
                <template slot="waitwise_status" slot-scope="text, data">
                    <a-switch v-model="data.waitwise_enable" @change="update_status(data, 'waitwise', $event)" />
                </template>
                <template slot="autojoin_status" slot-scope="text, data">
                    <a-switch v-model="data.autojoin_enable" @change="update_status(data, 'autojoin', $event)" />
                </template>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count"
                show-less-items />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "Sid",
            dataIndex: "sid",
            width: 320
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Timezone",
            dataIndex: "timezone"
        },
        {
            title: "AI Agents Status",
            dataIndex: "ai_enable",
            scopedSlots: { customRender: 'ai_status' }
        },
        {
            title: "Waitwise",
            dataIndex: "waitwise_enable",
            scopedSlots: { customRender: 'waitwise_status' }
        },
        {
            title: "Autojoin",
            dataIndex: "autojoin_enable",
            scopedSlots: { customRender: 'autojoin_status' }
        }
    ];

    export default {
        name: 'minipass_merchant_list',
        components: {
        },
        data() {
            return {
                search: '',
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                merchant_list: [],
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                sort_by: 'id',
            };
        },
        beforeMount() {},
        async created() {
            this.get_minipass_merchant_list();
        },
        watch: {
        },
        mounted() {
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getMerchantList: 'merchant/getMerchantList',
                updateMerchantStatus: 'merchant/updateMerchantStatus',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            turnPage(e) {
                this.get_minipass_merchant_list();
            },
            async update_status(data, type, evt) {
                if (type == 'autojoin') {
                    if (data.waitwise_enable == 0) {
                        this.$message.error('Please enable Waitwise first');
                        data.autojoin_enable = 0;
                        return;
                    }
                }

                let res = await this.updateMerchantStatus({
                    sid: data.sid,
                    enable: evt ? 1 : 0,
                    type: type
                });

                if (res.code == 100000) {
                    this.get_minipass_merchant_list();
                    this.$message.success('Updated successfully');
                }
            },
            async get_minipass_merchant_list() {
                let { list, total_count, total_page } = await this.getMerchantList({
                    search: encodeURIComponent(this.search),
                    page: this.cur_page,
                    sort_by: this.sort_by
                });

                this.merchant_list = list;
                this.total_page = total_page;
                this.total_count = total_count;
            },
            doKeyUp(e) {
                if (e.keyCode === 13) {
                    this.get_minipass_merchant_list();
                }
            }
        }
    };
</script>

<style>
</style>
