import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    getBirdMessageList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/bird/list?sid=${post_data.sid}&from_phone_num=${encodeURIComponent(post_data.from_phone_num)}&to_phone_num=${encodeURIComponent(post_data.to_phone_num)}&foreign_id=${post_data.foreign_id}&status=${post_data.status}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getSystemData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/system/data`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateCrontabSetting({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/monitor/crontab/update`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getLiveChatMessageList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/livechat/list?sid=${post_data.sid}&store_phone_num=${post_data.store_phone_num}&to_phone_num=${post_data.to_phone_num}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    sendLiveChatMessage({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/monitor/livechat/send`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getWaitingTimeData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/waiting/time?sid=${post_data.sid}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getWaitingListData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/waiting/list?sid=${post_data.sid}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getProTransactionList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/monitor/pro/transaction/list?stime=${post_data.stime}&etime=${post_data.etime}&page=${post_data.page}&page_size=${post_data.page_size}&cid=${post_data.cid}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
