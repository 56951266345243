require('es6-promise').polyfill(); //es6 promise
require('isomorphic-fetch'); //fetch

import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import router from './router';
import i18n from './locales';
import antDesign from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import VueClipboard from 'vue-clipboard2';

import './assets/css/vue-mdc-adapter.min.css';

import VueLazyload from 'vue-lazyload';
// import './kits/bee';

// vue-json-excel插件来实现简单Excel表格的导出功能
import JsonExcel from 'vue-json-excel'
 // 将Excel导出器作为vue实例的组件
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
});

Vue.use(antDesign)
Vue.use(VueClipboard)

// Vue.prototype.trans = trans;
// Vue.prototype.transNames = transNames;

export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        i18n,
        render: (h) => h(App)
    });

    return { app, router };
}

window.onload = () => createApp();
