import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    getMerchantList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/list?search=` + post_data.search + '&page=' + post_data.page + '&page_size=20&sort_by=' + post_data.sort_by)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateMerchantStatus({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/status/update?sid=` + post_data.sid + '&enable=' + post_data.enable + '&type=' + post_data.type)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getMerchantTopn({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/topn?topn=50`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    doWaitlistCheck({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/waitlist/check?sid=` + post_data.sid + '&check_type=' + post_data.check_type + '&target_date=' + post_data.target_date + '&target_time=' + post_data.target_time + '&people_num=' + post_data.people_num)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
