<template>
    <div class="main_bd">
        <div class="head_title">Membership Management</div>
        <div class="in_title">Membership in minipass</div>
        <div class="fileter">
            <div style="margin-top: 10px">
                <span class="date" style="margin-right: 10px;">
                    <a-input v-model="q" placeholder="phone / name" style="width: 150px" />
                </span>
                <span class="date">
                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                </span>
                <span style="margin: 0 10px">to</span>
                <span class="date">
                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" :disabled-date="disabledEndDate" />
                </span>
                <button class="done_btn" @click="fliterData">Search</button>
            </div>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="6">
                <a-statistic title="Total Count" :value="membership_data.total_count || 0" style="margin-right: 50px"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Now Month Count" :value="membership_data.now_month_count || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Last 7 Days Count" :value="membership_data.last_7_count || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Today Count" :value="membership_data.today_count || 0" class="demo-class"></a-statistic>
            </a-col>
        </a-row>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey='data => data.id' :columns="columns" :data-source="membership_list">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.idx }}</p>
                </a>
                <a slot="action" slot-scope="text, data">
                    <a-button type="primary" @click="showTransactionLog(data)">Trans log</a-button>
                    <br/>
                    <a-button style="margin-top: 5px;" type="primary" @click="toDetail(data)">Details</a-button>
                    <a-button style="margin-top: 5px;" type="primary" @click="toActivities(data)">Activities</a-button>
                </a>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="10" v-model="page" :total="total_count"
                show-less-items />
        </div>
        <a-modal :width="1000" v-model="showTransactionLogVisible" @ok="hideTransactionLog">
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Transaction Log</div>
                        <div>Phone: <span>{{ layer_member_data && layer_member_data.phone || '' }}</span></div>
                    </div>
                    <div style="padding-top: 38px;">User id: {{ layer_member_data && layer_member_data.id || '' }}</div>
                </div>
            </template>
            <div style="height: 300px; overflow: auto;">
                <a-table :pagination="false" :rowKey='data => data.id' :columns="transaction_columns" :data-source="transaction_list">
                    <a slot="index" slot-scope="text, data">
                        <p>No.{{ data.idx }}</p>
                    </a>
                    <a slot="pay_method" slot-scope="text, data">
                        <p v-if="data.is_auto_renew == '1'">Auto renew</p>
                        <p v-else>In-app</p>
                    </a>
                </a-table>
            </div>
        </a-modal>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <a-descriptions>
                <div class="detail_title" slot="title">
                    <div class="modal_head">
                        <div>{{ curGuest.name == 'N/A' ? 'unnamed' : curGuest.name }}</div>
                        <a-tag v-if="curGuest.blocklist == 1" color="black">
                            {{ status[4].label }}
                        </a-tag>
                        <a-tag v-else color="blue">
                            {{ parseStatus(curGuest) }}
                        </a-tag>
                    </div>
                </div>
                <a-descriptions-item label="Phone" :span="2">
                    {{ curGuest.phone }}
                </a-descriptions-item>
                <a-descriptions-item label="E-mail" :span="1">
                    {{ curGuest.email }}
                </a-descriptions-item>
                <a-descriptions-item label="Birthday" :span="2">
                    {{ curGuest.birthday }}
                </a-descriptions-item>
                <a-descriptions-item label="Location" :span="1">
                    {{ curGuest.city }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Activity log</div>
                <a-descriptions-item label="Join date" :span="2">
                    {{ formatDate(curGuest.createdate) }}
                </a-descriptions-item>
                <a-descriptions-item label="Last open" :span="1">
                    {{ formatDate(Number(curGuest.last_active_time)) }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Business record</div>
                <a-descriptions-item label="Waitlist total" :span="1">
                    {{ count_param.wait_count }}
                </a-descriptions-item>
                <a-descriptions-item label="Reservation total" :span="1">
                    {{ count_param.reservation_count }}
                </a-descriptions-item>
                <a-descriptions-item label="Pass total" :span="1">
                    {{ pass_count }}
                </a-descriptions-item>
            </a-descriptions>
            <div slot="footer" style="text-align: center; margin: 20px 0">
                <a-button @click="handleOk"> Cancel </a-button>
            </div>
        </a-modal>
        <a-modal :width="1200" v-model="showActivitiesVisible" @ok="handleActivitiesOk">
            <a-descriptions>
                <div class="detail_title" slot="title">
                    <div class="modal_head">
                        <div>{{ curGuest.name == 'N/A' ? 'unnamed' : curGuest.name }}</div>
                        <a-tag :color="curGuest.pro_status_text === 'expired' ? 'red' : 'blue'">
                            {{ curGuest.pro_status_text }}
                        </a-tag>
                    </div>
                </div>
                <a-descriptions-item label="Phone" :span="2">
                    {{ curGuest.phone }}
                </a-descriptions-item>
                <a-descriptions-item label="E-mail" :span="1">
                    {{ curGuest.email }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Auto-join log</div>
                <div style="height: 300px; overflow: auto;">
                    <a-table :pagination="false" :loading="is_loading" :rowKey='data => data.id' :columns="autojoin_columns" :data-source="membership_autojoin_list">
                    </a-table>
                </div>
            </a-descriptions>
            <div slot="footer" style="text-align: center; margin: 20px 0">
                <a-button @click="handleActivitiesOk"> Cancel </a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment";

    const columns = [
        {
            title: "No.",
            dataIndex: "index"
        },
        {
            title: "Phone",
            dataIndex: "phone",
            width: 150
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "User name",
            dataIndex: "name"
        },
        {
            title: "Sex",
            dataIndex: "gender"
        },
        {
            title: "DOB",
            dataIndex: "birthday"
        },
        {
            title: "Join time",
            dataIndex: "pro_jointime"
        },
        {
            title: "Sub type",
            dataIndex: "pro_sub_type"
        },
        {
            title: "Expiration time",
            dataIndex: "pro_expiredtime"
        },
        {
            title: "Auto renew",
            dataIndex: "pro_autorenew_text"
        },
        {
            title: "Status",
            dataIndex: "pro_status_text"
        },
        {
            title: "Action",
            dataIndex: "action",
            scopedSlots: { customRender: "action" }
        }
    ];

    const autojoin_columns = [
        {
            title: "No.",
            dataIndex: "index",
            width: 70,
        },
        {
            title: "Merchant",
            dataIndex: "sname"
        },
        {
            title: "Auto-join create time",
            dataIndex: "create_time"
        },
        {
            title: "Auto-join join time",
            dataIndex: "preorder_time"
        },
        {
            title: "Real seat time",
            dataIndex: "real_seat_time"
        },
        {
            title: "Estimated seat time",
            dataIndex: "estimated_seat_time"
        },
        {
            title: "Status",
            dataIndex: "status"
        }
    ]

    const transaction_columns = [
        {
            title: "Transaction Id",
            dataIndex: "transaction_id"
        },
        {
            title: "Payment Method",
            dataIndex: "payment_type"
        },
        {
            title: "Payment Status",
            dataIndex: "pay_status"
        },
        {
            title: "Amount",
            dataIndex: "price"
        },
        {
            title: "Purchase Time",
            dataIndex: "purchase_date"
        },
        {
            title: "Renew Type",
            dataIndex: "renew_type"
        },
        {
            title: "Pay Method",
            dataIndex: "is_auto_renew",
            scopedSlots: { customRender: "pay_method" }
        },
        {
            title: "Old expired time",
            dataIndex: "old_expired_timestamp"
        },
        {
            title: "New expired time",
            dataIndex: "new_expired_timestamp"
        }
    ];

    export default {
        name: 'Member_ship',
        components: {
        },
        data() {
            return {
                columns,
                transaction_columns,
                page: 1,
                start_date: '',
                end_date: '',
                q: '',
                membership_list: [],
                total_count: 0,
                total_page: 0,
                membership_data: {},
                showTransactionLogVisible: false,
                transaction_list: [],
                layer_member_data: null,
                detailVisible: false,
                curGuest: {},
                status: [
                    {
                        label: 'new user',
                        value: '0'
                    },
                    {
                        label: 'active',
                        value: '1'
                    },
                    {
                        label: 'data deleted',
                        value: '2'
                    },
                    {
                        label: 'Account deleted',
                        value: '3'
                    },
                    {
                        label: 'blacklisted',
                        value: '4'
                    }
                ],
                showActivitiesVisible: false,
                membership_autojoin_list: [],
                autojoin_columns,
                is_loading: false,
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.search();
            this.getSummary();
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
                count_param: 'guest/get_count_param',
                pass_count: 'guest/get_pass_count',
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getMembershipList: 'guest/getMembershipList',
                getMembershipSummary: 'guest/getMembershipSummary',
                getProTransactionList: 'monitor/getProTransactionList',
                get_count_param: 'guest/updateGuestCount',
                get_pass_count: 'guest/getPassCount',
                getMembershipAutojoinList: 'guest/getMembershipAutojoinList',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async getAutoJoinList() {
                this.is_loading = true;
                this.membership_autojoin_list = [];
                
                let res = await this.getMembershipAutojoinList({
                    cid: this.curGuest.id,
                });

                this.membership_autojoin_list = res.list;
                this.is_loading = false;
            },
            async getSummary() {
                let res = await this.getMembershipSummary();
                this.membership_data = res;
            },
            async search() {
                let res = await this.getMembershipList({
                    q: this.q,
                    stimestamp: this.start_date ? moment(this.start_date + ' 00:00:00').valueOf() : '',
                    etimestamp: this.end_date ? moment(this.end_date + ' 23:59:59').valueOf() : '',
                    page: this.page
                });

                this.membership_list = res.list;
                this.total_count = res.total_count;
                this.total_page = res.total_page;
            },
            async search_transactions(item) {
                let data = await this.getProTransactionList({
                    page: 0,
                    page_size: 20,
                    cid: item.id,
                    stime: '',
                    etime: '',
                });

                if (data.code == 100000) {
                    this.transaction_list = data.data.list;
                }
            },
            showTransactionLog(data) {
                this.layer_member_data = data;
                this.showTransactionLogVisible = true;
                this.search_transactions(data);
            },
            hideTransactionLog() {
                this.showTransactionLogVisible = false;
            },
            disabledEndDate(current) {
                return current && current.valueOf() <= moment(this.start_date).valueOf();
            },
            fliterData(){
                // console.log(this.start_date, this.end_date)
                this.search();
            },
            turnPage(e) {
                this.search();
            },
            toDetail(data) {
                this.get_count_param({
                    cid: data.id
                });
                this.get_pass_count({
                    id: data.id
                });
                this.curGuest = { ...data };
                this.detailVisible = true;
            },
            handleOk() {
                this.detailVisible = false;
            },
            parseStatus(data) {
                if (data.status === '') {
                    return '';
                }
                if (data.status === 1) {
                    if (moment().add(-7, 'd') > moment(data.createdate)) {
                        return this.status[1].label;
                    } else {
                        return this.status[0].label;
                    }
                } else {
                    for (let index = 0; index < this.status.length; index++) {
                        if (this.status[index].value == data.status) {
                            return this.status[index].label;
                        }
                    }
                }
                return '';
            },
            formatDate(time) {
                if (!time) {
                    return 'N/A'
                }
                return moment.tz(time, 'America/New_York').format('MM/DD/YYYY');
            },
            toActivities(data) {    // console.log(data)
                this.curGuest = { ...data };
                this.showActivitiesVisible = true;

                this.getAutoJoinList();
            },
            handleActivitiesOk() {
                this.showActivitiesVisible = false;
            }
        }
    };
</script>

<style>
    .in_title {
        font-size: 16px;
        text-align: center;
    }
    .fileter {
        text-align: right;
        margin-top: 30px;
        font-size: 16px;
    }
    .date {
        display: inline-block;
        width: 200px;
    }
    .done_btn {
        width: 82px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: rgba(116, 39, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
</style>
